import head from "lodash/head";
import React, { useCallback, useState } from "react";

import { toSourceUrls } from "../../api/sources";
import DownloadSource, { toPDFFileName } from "./DownloadSource";
import Source from "./Source";

function toFileDownloadIndex(
  index: number,
  sourceList: DocumentList | null,
): number | undefined {
  const [, documents] = head(sourceList) || ["", []];
  if (documents.length < 2) {
    return undefined;
  }
  return index + 1;
}

interface PDFViewerProps {
  selectedPayment: PaymentMessage;
}

export default function PDFViewer({ selectedPayment }: PDFViewerProps) {
  const [width, setWidth] = useState<number>();

  const section = useCallback((node: HTMLElement) => {
    if (!node) {
      return;
    }
    const percentageOfWidth = 0.7;
    setWidth(node.getBoundingClientRect().width * percentageOfWidth);
  }, []);

  return (
    <section style={{ fontFamily: "Inter Regular" }} ref={section}>
      {toSourceUrls(selectedPayment.documents).map((url, index) => (
        <div key={url}>
          <div
            style={{ display: "flex", justifyContent: "left", width: "100%" }}
          >
            <DownloadSource
              url={url}
              downloadFileName={toPDFFileName(
                selectedPayment.payer,
                selectedPayment.paymentDate,
                toFileDownloadIndex(index, selectedPayment.documents),
              )}
              showDownloadText
            />
          </div>
          <Source url={url} pageWidth={width} />
        </div>
      ))}
    </section>
  );
}
