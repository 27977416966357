import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import React, { useState } from "react";

import { CredentialsDeleteDialog } from "../SettingsDialog/CredentialsDeleteDialog";
import { CredentialsSettingsDialog } from "../SettingsDialog/CredentialsSettingsDialog";
import { getDefaultColumns } from "./CredentialsTableColumns";

type UnmaskCredential = {
  unmaskValue: (attributeName: string) => Promise<string>;
};

interface CredentialsTableProps {
  generateRow: () => Partial<UserCredentialsMessage & UnmaskCredential>[];
  practiceNames: string[];
  credentialNames: string[];
  supportedSourcesMap: Record<string, SourceMessage>;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export function CredentialsTable({
  generateRow,
  practiceNames,
  credentialNames,
  supportedSourcesMap,
  updateSnackBar,
}: CredentialsTableProps) {
  const row = generateRow();
  const [selectedRow, setSelectedRow] =
    useState<Partial<UserCredentialsMessage> | null>(null);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const openEditModal = async (
    credential: Partial<UserCredentialsMessage> & UnmaskCredential,
  ) => {
    const unmaskedCredential = {
      ...credential,
      username: await credential.unmaskValue("username"),
      password: await credential.unmaskValue("password"),
    };
    setSelectedRow(unmaskedCredential);
    setEditOpen(true);
  };
  const closeEditModal = () => {
    setEditOpen(false);
    setSelectedRow(null);
  };

  const openDeleteModal = (credential: Partial<UserCredentialsMessage>) => {
    setSelectedRow(credential);
    setDeleteOpen(true);
  };
  const closeDeleteModal = () => {
    setDeleteOpen(false);
    setSelectedRow(null);
  };

  return (
    <>
      <Box sx={{ padding: "1rem 0" }}>
        <DataGridPro
          columns={getDefaultColumns(
            practiceNames,
            credentialNames,
            supportedSourcesMap,
            openEditModal,
            openDeleteModal,
          )}
          rows={row}
          rowHeight={72}
          disableRowSelectionOnClick
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            sorting: {
              sortModel: [{ field: "updatedAt", sort: "desc" }],
            },
          }}
          pageSizeOptions={[10, 20, 40]}
          sx={{
            [`& .${gridClasses.columnHeader}`]: {},
            [`& .MuiDataGrid-main .${gridClasses.columnHeaders}`]: {
              margin: 0,
              borderBottom: "solid 1px #EAECF0",
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            },
            [`& .${gridClasses.columnHeaderTitle}`]: {
              color: "#475467",
              fontSize: "12px",
              padding: "1.5rem 0.75rem",
              weight: 500,
            },
            [`& .${gridClasses.cell}`]: {
              color: "#475467",
              fontSize: "14px",
              weight: 400,
              padding: "0 1.375rem",
            },
            [`& .${gridClasses.row}`]: {
              borderBottom: "solid 1px #EAECF0",
              borderRadius: 0,
            },
            border: "1px solid rgba(208, 213, 221, 0.5)",
            boxShadow:
              "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
            borderRadius: "13px",
          }}
        />
      </Box>
      {selectedRow && (
        <>
          <CredentialsSettingsDialog
            credentials={selectedRow}
            updateSnackBar={updateSnackBar}
            supportedSourcesMap={supportedSourcesMap}
            open={editOpen}
            onClose={closeEditModal}
          />
          <CredentialsDeleteDialog
            credentials={selectedRow}
            updateSnackBar={updateSnackBar}
            open={deleteOpen}
            onClose={closeDeleteModal}
          />
        </>
      )}
    </>
  );
}
