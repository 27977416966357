import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const MAX_FILE_SIZE = 15 * 1024 * 1024; // 15MB

interface UploadFileProps {
  file: File | null;
  setFile: (file: File | null) => void;
  onError: (error: string | null) => void;
  allowedFileTypes: string[];
}

export default function UploadFile({
  file,
  setFile,
  onError,
  allowedFileTypes,
}: UploadFileProps) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onError(null);
    if (acceptedFiles.length > 0) {
      const filetoUpload = acceptedFiles[0];
      if (!allowedFileTypes.includes(filetoUpload.type)) {
        setFile(null);
        onError(
          `Please only provide one of the following file types: ${allowedFileTypes.join(", ")}`,
        );
        return;
      }
      if (filetoUpload.size > MAX_FILE_SIZE) {
        setFile(null);
        onError(`File size should not exceed 15MB`);
        return;
      }
      setFile(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box
      sx={{
        border: "2px dashed grey",
        borderRadius: 1,
        p: 3,
        textAlign: "center",
        mb: 2,
        cursor: "pointer",
        "&:hover": { borderColor: "primary.main" },
      }}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...getRootProps()}
    >
      <input
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...getInputProps()}
        hidden
      />
      <Typography variant="body1" color="textSecondary">
        {isDragActive
          ? "Drop the file here..."
          : "Drag & drop or click to upload"}
      </Typography>
      {file && (
        <Typography variant="body2" mt={2}>
          {file.name}
        </Typography>
      )}
    </Box>
  );
}
