import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Button from "@mui/material/Button";
import React, { ReactElement, useState } from "react";

import useApi from "../../api/apiConfig";

export function toPDFFileName(
  payer: string | null,
  date: string | null,
  index?: number,
): string {
  const prefix = payer
    ? `${payer.toLowerCase().replaceAll(/ /g, "_")}`
    : "document";
  const suffix = date ? `${date.toLowerCase()}` : "download";
  if (index) {
    return `${prefix}-${suffix}_${index}.pdf`;
  }
  return `${prefix}-${suffix}.pdf`;
}

interface DownloadSourceProps {
  url: string;
  downloadFileName: string;
  showDownloadText?: boolean;
}

export default function DownloadSource({
  url,
  downloadFileName,
  showDownloadText,
}: DownloadSourceProps): ReactElement | null {
  const { getClient } = useApi();
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      const api = await getClient();
      const response = await api.get(url, { responseType: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(response.data);
      link.download = downloadFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Failed to download pdf: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      style={{ textTransform: "none" }}
      onClick={handleDownload}
      disabled={isLoading}
      aria-label="Download PDF"
    >
      {showDownloadText ? "Download PDF" : null}
      <CloudDownloadIcon
        style={{ marginLeft: showDownloadText ? "8px" : "0px" }}
      />
    </Button>
  );
}

DownloadSource.defaultProps = {
  showDownloadText: false,
};
