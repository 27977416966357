import dayjs from "dayjs";
import head from "lodash/head";
import last from "lodash/last";

import { ClaimStatus } from "../../../../status";

type ClaimsByPayer = Map<string, ClaimMessage[]>;

enum DenialType {
  DenialFull = "DenialFull",
  DenialPartial = "DenialPartial",
  PaidFull = "PaidFull",
}

// TODO: Decide whether we want to surface count pending claims in the reporting
// dashboard given that they are currently filtered completely on the frontend.
const CLAIM_STATUS_LABELS = ["Approved", "Denied", "Partially Denied"];

const CLAIM_STATUS_MAP = {
  [ClaimStatus.APPROVED]: CLAIM_STATUS_LABELS.indexOf("Approved"),
  [ClaimStatus.DENIED]: CLAIM_STATUS_LABELS.indexOf("Denied"),
  [ClaimStatus.PARTIALLY_DENIED]:
    CLAIM_STATUS_LABELS.indexOf("Partially Denied"),
  [ClaimStatus.PENDING]: CLAIM_STATUS_LABELS.indexOf("Pending"),
};

const MONTH_LABELS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const PAYMENT_TYPE_LABELS = [
  "Completed Denial",
  "Full Payment",
  "Partial Denial",
];

const PAYMENT_TYPE_MAP = {
  [DenialType.DenialFull]: PAYMENT_TYPE_LABELS.indexOf("Completed Denial"),
  [DenialType.PaidFull]: PAYMENT_TYPE_LABELS.indexOf("Full Payment"),
  [DenialType.DenialPartial]: PAYMENT_TYPE_LABELS.indexOf("Partial Denial"),
};

function uniquePayersFrom(claims: ClaimMessage[]): string[] {
  const payers = claims.reduce((acc, claim) => {
    if (!claim.payer) {
      return acc;
    }
    if (!acc.has(claim.payer)) {
      acc.add(claim.payer);
    }
    return acc;
  }, new Set<string>());
  return Array.from(payers);
}

function uniquePracticesFrom(claims: ClaimMessage[]): string[] {
  return Array.from(
    new Set(
      claims.filter((claim) => claim.practice).map((claim) => claim.practice),
    ),
  );
}

function toClaimsByPayer(claims: ClaimMessage[]): ClaimsByPayer {
  return claims.reduce<ClaimsByPayer>((byPayer, claim) => {
    if (!byPayer.has(claim.payer)) {
      byPayer.set(claim.payer, []);
    }
    byPayer.get(claim.payer)?.push(claim);
    return byPayer;
  }, new Map());
}

function claimToDenialType(claim: ClaimWithProcedureMessage): DenialType {
  if (
    !claim.Procedure ||
    claim.Procedure.every((obj) => !obj.procedureDenialCode)
  ) {
    return DenialType.PaidFull;
  }
  const hasProcedureDenialCode = claim.Procedure.every(
    (obj) => obj.procedurePayerPays === 0,
  );
  if (hasProcedureDenialCode) {
    return DenialType.DenialFull;
  }
  return DenialType.DenialPartial;
}

function claimsToDenialRate(claims: ClaimMessage[]): number {
  const deniedClaims = claims.filter(
    (claim) => claim.claimStatus === ClaimStatus.DENIED,
  );
  return (deniedClaims.length / claims.length) * 100;
}

function isClaimMessage(
  message: ClaimMessage | PaymentMessage,
): message is ClaimMessage {
  return Object.hasOwn(message, "wieldyClaimDate");
}

function getDate(message: ClaimMessage | PaymentMessage): string | null {
  if (isClaimMessage(message)) {
    return message.wieldyClaimDate;
  }
  return message.paymentDate;
}

function getMonthLabels(messages: PaymentMessage[] | ClaimMessage[]): string[] {
  const sortedDates = messages.map(getDate).sort();
  const earliest = head(sortedDates) ?? undefined;
  const latest = last(sortedDates) ?? undefined;
  const months = [];
  let currentDate = dayjs(earliest ?? 0).set("date", 1);
  const latestDate = dayjs(latest);
  while (currentDate.format("YYYY-MM") <= latestDate.format("YYYY-MM")) {
    months.push(currentDate.format("MMM-YYYY"));
    currentDate = currentDate.add(1, "month");
  }
  return months;
}

export {
  CLAIM_STATUS_LABELS,
  CLAIM_STATUS_MAP,
  MONTH_LABELS,
  PAYMENT_TYPE_LABELS,
  PAYMENT_TYPE_MAP,
  DenialType,
  isClaimMessage,
  uniquePayersFrom,
  uniquePracticesFrom,
  toClaimsByPayer,
  claimToDenialType,
  claimsToDenialRate,
  getMonthLabels,
};
