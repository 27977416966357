import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

interface TaskCardProps {
  title: string;
  priority: "LOW" | "MEDIUM" | "HIGH";
  practice: string;
  createdAt: string;
  mandatory: boolean;
  onAction: () => void;
}

function TaskCard({
  title,
  practice,
  priority,
  createdAt,
  mandatory,
  onAction,
}: TaskCardProps) {
  const [expanded, setExpanded] = useState(false);
  const priorityColors = {
    HIGH: "red",
    MEDIUM: "orange",
    LOW: "green",
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const formattedDate = new Date(createdAt).toLocaleDateString();

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        width: "100%",
        "@media (max-width: 600px)": {
          padding: "0.5rem",
        },
        cursor: "pointer",
      }}
      onClick={handleExpandClick}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            marginBottom: "0.5rem",
            maxWidth: "60%",
            marginRight: "1rem",
          }}
        >
          {title}
        </Typography>
        <div>
          <Button variant="contained" color="primary" onClick={onAction}>
            {mandatory ? "Review" : "Update"}
          </Button>
          <IconButton onClick={handleExpandClick}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            Practice : {practice}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Created At : {formattedDate}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Priority :{" "}
            <span style={{ color: priorityColors[priority] }}>{priority}</span>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default TaskCard;
