import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useQueryClient from "../../api/query";
import TaskCard from "./TaskCard";
// import TaskModal from "./TaskModal";

interface Task {
  id: string;
  title: string;
  practice: string;
  description: string;
  category: "CLAIMS" | "CREDENTIALS";
  priority: "LOW" | "MEDIUM" | "HIGH";
  status: "PENDING" | "COMPLETED";
  link?: string;
  createdAt: string;
  updatedAt: string;
}

function TaskManager() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [tasks, setTasks] = useState<Task[]>([]);
  // const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  useEffect(() => {
    const fetchTasks = async () => {
      const fetchedTasks = await queryClient.getTasks();
      setTasks(fetchedTasks);
    };

    fetchTasks();
  }, []);

  const handleMarkCompleted = async (taskId: string) => {
    await queryClient.completeTask(taskId);
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
  };

  const handleTaskClick = (task: Task) => {
    if (task.status === "PENDING") {
      handleMarkCompleted(task.id); // Mark the task as completed
    }
    if (task.link) {
      navigate(task.link); // Redirect for tasks with a link
    }
    // else {
    //   setSelectedTask(task); // Open modal for tasks without a link
    // }
  };

  // const handleModalClose = () => {
  //   setSelectedTask(null);
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: "1rem",
        textAlign: "left",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      {tasks.length === 0 ? (
        <Typography variant="h6">
          Great work, You have finished all the tasks for now 👏
        </Typography>
      ) : (
        tasks.map((task) => (
          <TaskCard
            key={task.id}
            title={task.title}
            practice={task.practice}
            priority={task.priority}
            createdAt={task.createdAt}
            mandatory={task.status === "PENDING"}
            onAction={() => handleTaskClick(task)}
          />
        ))
      )}
      {/* {selectedTask && (
        <TaskModal task={selectedTask} onClose={handleModalClose} />
      )} */}
    </Box>
  );
}

export default TaskManager;
