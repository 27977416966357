import {
  GridColumnVisibilityModel,
  GridEvents,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { MutableRefObject, useEffect } from "react";

const EVENTS: GridEvents[] = [
  "filterModelChange",
  "sortModelChange",
  "columnVisibilityModelChange",
  "columnIndexChange",
];

// Columns which are to be hidden not in dev mode, even if their visbility is persisted.
const DEV_MODE_COLUMNS: Set<string> = new Set(["reviewStatus"]);

interface Persistence {
  persist: (state: GridInitialStatePro) => void;
  retrieve: () => GridInitialStatePro | undefined;
}

class LocalStoragePersistence implements Persistence {
  private key: string;

  constructor(key: string) {
    this.key = key;
  }

  persist(state: GridInitialStatePro): void {
    window.localStorage.setItem(this.key, JSON.stringify(state));
  }

  retrieve(): GridInitialStatePro | undefined {
    return (
      JSON.parse(window.localStorage.getItem(this.key) ?? "null") ?? undefined
    );
  }
}

function toPersistState(state: GridInitialStatePro): GridInitialStatePro {
  return {
    columns: state.columns,
    filter: state.filter,
    sorting: state.sorting,
  };
}

function onChangeFn(
  persistence: Persistence,
  apiRef: MutableRefObject<GridApiPro>,
): () => void {
  return () => {
    persistence.persist(toPersistState(apiRef.current.exportState()));
  };
}

// Hides persisted dev mode only columns when not in dev mode.
function resolveColumnsForMode(
  state: GridInitialStatePro | undefined,
  devMode: boolean,
): GridInitialStatePro | undefined {
  if (state === undefined) {
    return undefined;
  }
  const columnVisibilityModel = state.columns?.columnVisibilityModel ?? {};
  return {
    ...state,
    columns: {
      ...state.columns,
      columnVisibilityModel: Object.keys(columnVisibilityModel).reduce(
        (acc: GridColumnVisibilityModel, field: string) => {
          acc[field] =
            !devMode && DEV_MODE_COLUMNS.has(field)
              ? false
              : columnVisibilityModel[field];
          return acc;
        },
        {},
      ),
    },
  };
}

interface DataGridPersistence {
  apiRef: MutableRefObject<GridApiPro>;
  retrieveState: (devMode: boolean) => GridInitialStatePro | undefined;
}

/**
 * Handles saving of datagrid table state to local storage, so that filters and sorts
 * can be persisted between page loads.
 * @param key {string} The key to be used in local storage to reference the state.
 * @returns {DataGridPersistence}
 */
const useDataGridPersistence = (key: string): DataGridPersistence => {
  const persistence = new LocalStoragePersistence(key);
  const apiRef = useGridApiRef();
  useEffect(() => {
    const unsubscribeFns = EVENTS.map((event) =>
      apiRef.current.subscribeEvent(event, onChangeFn(persistence, apiRef)),
    );
    return () => unsubscribeFns.forEach((fn) => fn());
  }, []);
  return {
    apiRef,
    retrieveState: (devMode: boolean) =>
      resolveColumnsForMode(persistence.retrieve(), devMode),
  };
};

export default useDataGridPersistence;
export { LocalStoragePersistence, toPersistState, resolveColumnsForMode };
