import { Box, Typography } from "@mui/material";
import React from "react";

import TaskManager from "../TaskManager/TaskManager";

interface CopilotAssistProps {
  fullname: string;
}
function CopilotAssist({ fullname }: CopilotAssistProps) {
  return (
    <Box
      sx={{
        padding: "2rem",
        margin: "0 auto",
        textAlign: "left",
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "100%",
        "@media (max-width: 600px)": {
          padding: "1rem",
        },
      }}
    >
      {/* Greeting */}
      <Typography variant="h4" component="h1" gutterBottom>
        Hello {fullname ? `${fullname.split(" ")[0]}!` : ""}
      </Typography>

      {/* Task Manager */}
      <TaskManager />
    </Box>
  );
}

export default CopilotAssist;
