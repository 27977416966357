import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

interface HeroProps {
  callToAction: React.ReactNode;
}

export function Hero({ callToAction }: HeroProps) {
  const theme = useTheme();

  return (
    <Box sx={{ paddingTop: theme.spacing(4) }}>
      <Box sx={{ marginBottom: theme.spacing(4) }}>
        <Grid
          container
          sx={{
            padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
            borderBottom: "1px solid rgba(37, 35, 63, 0.12)",
          }}
        >
          <Grid container flexDirection="row" justifyContent="space-between">
            <Grid item display="flex" alignItems="center">
              <Typography variant="h5" sx={{ fontWeight: "600" }}>
                <span style={{ marginRight: theme.spacing(2) }}>
                  Credentials
                </span>
              </Typography>
              <Divider
                orientation="vertical"
                sx={{ margin: `0 ${theme.spacing(3)}` }}
                flexItem
              />
              <Typography
                variant="caption"
                sx={{ fontSize: "14px", color: theme.palette.text.secondary }}
              >
                Browse and manage your saved insurance credentials securely or
                add new login information.
              </Typography>
            </Grid>

            {callToAction}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
