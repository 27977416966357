import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { type Control, Controller } from "react-hook-form";

import { ErrorHelperText } from "../../PatientsDashboard/ui/ErrorHelper";
import { InputLabelText } from "../ui/InputLabelText";
import { InputWrapper } from "../ui/InputWrapper";
import { LineBreak } from "../ui/LineBreak";
import { UpdatePayerCredentialsFormFields } from "./PayerCredentialsForm.zod";

interface AddPayerCredentialFormFieldProps {
  control: Control<UpdatePayerCredentialsFormFields>;
  currentCredentials: Partial<UserCredentialsMessage>;
  supportedSourcesMap: Record<string, SourceMessage>;
}

export function UpdatePayerCredentialFormFields({
  control,
  currentCredentials,
  supportedSourcesMap,
}: AddPayerCredentialFormFieldProps) {
  const { sourceId } = currentCredentials;
  const selectedPayer = sourceId ? supportedSourcesMap[sourceId] : null;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0",
        padding: "1rem 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <InputLabelText>Credential</InputLabelText>

        {(currentCredentials.sourceName ?? currentCredentials.name) && (
          <Typography sx={{ marginRight: "auto", color: "#475467" }}>
            {currentCredentials.sourceName ?? currentCredentials.name}
          </Typography>
        )}
      </Box>

      <Controller
        name="username"
        defaultValue={currentCredentials.username}
        control={control}
        render={({
          field: { ref, name, value, onBlur, onChange },
          fieldState: { error },
        }) => (
          <FormControl
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <FormLabel>
              <InputLabelText>Username</InputLabelText>
            </FormLabel>
            <InputWrapper>
              <TextField
                name={name}
                value={value}
                type="text"
                variant="outlined"
                placeholder="Update Username"
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{
                  "data-testid": "usernameInput",
                  autoComplete: "username",
                  sx: {
                    border: "none",
                  },
                }}
                sx={{
                  backgroundColor: "#F9FAFB",
                  width: "100%",
                  fontSize: "1rem",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    border: "none",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "solid 1px rgba(208, 213, 221, 0.5)",
                  },
                }}
              />
              <ErrorHelperText message={error?.message} />
            </InputWrapper>
          </FormControl>
        )}
      />

      <Controller
        name="website"
        defaultValue={currentCredentials.website ?? undefined}
        control={control}
        render={({
          field: { ref, name, value, onBlur, onChange },
          fieldState: { error },
        }) => (
          <FormControl
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <FormLabel>
              <InputLabelText>Website</InputLabelText>
            </FormLabel>
            <InputWrapper>
              <TextField
                disabled={!currentCredentials.website && !!selectedPayer}
                name={name}
                value={value}
                type="text"
                variant="outlined"
                placeholder={selectedPayer?.website || "Update Website"}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{
                  "data-testid": "labelInput",
                  autoComplete: "off",
                  sx: {
                    border: "none",
                  },
                }}
                sx={{
                  backgroundColor: "#F9FAFB",
                  width: "100%",
                  margin: "0 auto",
                  fontSize: "1rem",
                  maxWidth: "785px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    border: "none",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "solid 1px rgba(208, 213, 221, 0.5)",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    opacity: currentCredentials?.website ? 1 : "none",
                  },
                }}
              />
              <ErrorHelperText message={error?.message} />
            </InputWrapper>
          </FormControl>
        )}
      />

      <Controller
        name="notes"
        defaultValue={currentCredentials.notes ?? undefined}
        control={control}
        render={({
          field: { ref, name, value, onBlur, onChange },
          fieldState: { error },
        }) => (
          <FormControl
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <FormLabel>
              <InputLabelText>Notes</InputLabelText>
            </FormLabel>
            <InputWrapper>
              <TextField
                multiline
                name={name}
                value={value}
                rows={4}
                type="text"
                variant="outlined"
                placeholder="Update Notes"
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{
                  "data-testid": "labelInput",
                  autoComplete: "off",
                  sx: {
                    border: "none",
                  },
                }}
                sx={{
                  backgroundColor: "#F9FAFB",
                  width: "100%",
                  margin: "0 auto",
                  fontSize: "1rem",
                  maxWidth: "785px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    border: "none",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "solid 1px rgba(208, 213, 221, 0.5)",
                  },
                  // "& .MuiInputBase-root": {
                  //   height: "10rem",
                  // },
                }}
              />
              <ErrorHelperText message={error?.message} />
            </InputWrapper>
          </FormControl>
        )}
      />

      <LineBreak />

      <Controller
        name="password"
        defaultValue={currentCredentials.password}
        control={control}
        render={({
          field: { ref, name, value, onBlur, onChange },
          fieldState: { error },
        }) => (
          <FormControl
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              marginTop: "1rem",
            }}
          >
            <FormLabel>
              <InputLabelText>Password</InputLabelText>
            </FormLabel>
            <InputWrapper>
              <TextField
                name={name}
                value={value}
                type="password"
                variant="outlined"
                placeholder="Enter Password"
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{
                  "data-testid": "passwordInput",
                  autoComplete: "current-password",
                }}
                sx={{
                  backgroundColor: "#F9FAFB",
                  width: "100%",
                  margin: "0 auto",
                  fontSize: "1rem",
                  maxWidth: "785px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "solid 1px rgba(208, 213, 221, 0.5)",
                  },
                }}
              />
              <ErrorHelperText message={error?.message} />
            </InputWrapper>
          </FormControl>
        )}
      />
    </Box>
  );
}
