import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { GridToolbar, GridToolbarContainer } from "@mui/x-data-grid-pro";
import React from "react";

import { isDevMode } from "../../utils/utils";
import Modal from "../Modal";

interface EditToolbarProps {
  addEntityForm: React.ReactNode;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isDisabled: boolean;
  enableAddToolBar: boolean;
  entityName: string;
  setOpenModal: (open: boolean) => void;
  openModal: boolean;
  enableGridToolbar: boolean;
}

export default function AddToolbar({
  addEntityForm,
  handleSubmit,
  isDisabled,
  enableAddToolBar,
  entityName,
  setOpenModal,
  openModal,
  enableGridToolbar,
}: EditToolbarProps) {
  const theme = useTheme();

  return (
    <GridToolbarContainer>
      {enableAddToolBar && isDevMode() && (
        <>
          <Modal
            title={`Add/Edit ${entityName}`}
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
          >
            <Grid
              container
              justifyContent="center"
              sx={{ height: "100%", margin: `${theme.spacing(7)} 0` }}
            >
              <Grid item xs={12} sm={5}>
                <Box
                  component="form"
                  onSubmit={(data) => {
                    handleSubmit(data);
                    setOpenModal(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {addEntityForm}
                  <Button
                    fullWidth
                    sx={{ borderRadius: "8px", marginBottom: "2rem" }}
                    type="submit"
                    autoFocus
                    color="success"
                    variant="contained"
                    disabled={isDisabled}
                    data-testid="saveEntity"
                  >
                    {`Save ${entityName}`}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Modal>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Add Row
          </Button>
        </>
      )}
      {enableGridToolbar && <GridToolbar />}
    </GridToolbarContainer>
  );
}
