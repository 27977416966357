import head from "lodash/head";

import { getBackendAPI } from "./apiConfig";

function makeSourceUrl(document: string): string {
  const { search } = window.location;
  const encoded = encodeURIComponent(document);
  return search.length
    ? `${getBackendAPI()}documents/${search}&d=${encoded}`
    : `${getBackendAPI()}documents/?d=${encoded}`;
}

function toSourceUrls(sources: DocumentList | null): string[] {
  if (!sources?.length) {
    return [];
  }
  const [, documents] = head(sources) || ["", []];
  if (!documents.length) {
    return [];
  }
  return documents.map((document) => makeSourceUrl(document.path));
}

function toSourceFileId(sources: DocumentList | null): string[] {
  if (!sources?.length) {
    return [];
  }
  const [, documents] = head(sources) || ["", []];
  if (!documents.length) {
    return [];
  }
  return documents.map((document) => document.fileId);
}

function fileToSource(file: FileMessage): DocumentList {
  return [[file.payer, [{ fileId: file.id, path: file.saved_location }]]];
}

export { makeSourceUrl, toSourceUrls, toSourceFileId, fileToSource };
