import Box from "@mui/material/Box";
import React from "react";

export function SupportText() {
  return (
    <Box component="span" sx={{ fontWeight: 600, fontSize: "10px" }}>
      Source Added
    </Box>
  );
}
