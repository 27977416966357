import { AlertColor } from "@mui/material/Alert/Alert";
import { AxiosError } from "axios";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import { getTodayISODate } from "../../utils/utils";
import {
  MAX_POLL_TIME,
  needsPolling,
  POLL_INTERVAL,
  PostedState,
  postingStatusInProgress,
  postingStatusSuccess,
} from "../posting";
import ClaimsListView from "./ClaimsListView";
import { ClaimsStore, ClaimsStoreAction } from "./store";

interface ClaimsDashboardProps {
  store: ClaimsStore;
  updateStore: Dispatch<ClaimsStoreAction>;
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  loadPractices: () => Promise<void>;
  selectedPractices: PracticeMessage[];
  supportedPayers: CredentialsSupportedPayersMessage[];
}

export default function ClaimsDashboard({
  store,
  updateStore,
  queryClient,
  updateSnackBar,
  loadPractices,
  selectedPractices,
  supportedPayers,
}: ClaimsDashboardProps) {
  const [isBatchPosting, updateIsBatchPosting] = useState<boolean>(false);
  const [pollingClaimIds, setPollingClaimIds] = useState<Set<string>>(
    new Set(),
  );
  const pollStartTimeRef = useRef<number | null>(null);

  useEffect(() => {
    if (pollingClaimIds.size === 0) {
      pollStartTimeRef.current = null;
      return () => {};
    }

    if (!pollStartTimeRef.current) {
      pollStartTimeRef.current = Date.now();
    }
    const pollInterval = setInterval(async () => {
      if (
        pollStartTimeRef.current &&
        Date.now() - pollStartTimeRef.current > MAX_POLL_TIME
      ) {
        setPollingClaimIds(new Set());
        pollStartTimeRef.current = null;
      }
      const claims = await queryClient.getClaimsProceduresAndPatient([]);
      const completedClaims = claims.filter(
        (claim) => pollingClaimIds.has(claim.wieldyId) && !needsPolling(claim),
      );
      completedClaims.forEach((claim) => {
        if (claim.postedState === PostedState[PostedState.AUTO_POSTED]) {
          updateSnackBar({
            severity: "success",
            message: "Successfully posted to PMS",
          });
        }
        if (
          [
            PostedState[PostedState.FAILURE],
            PostedState[PostedState.RETRY],
          ].includes(claim.postedState)
        ) {
          updateSnackBar({
            severity: "error",
            message:
              claim.postedLatestErrorMessage ||
              "There was an issue posting to PMS. Please reach out to Wieldy support.",
          });
        }
      });
      const newPollingIds = new Set(
        claims
          .filter((claim) => needsPolling(claim))
          .map((claim) => claim.wieldyId),
      );
      if (completedClaims.length === 0 && newPollingIds.size > 0) {
        updateSnackBar({
          severity: "info",
          message: `Hang tight. Claims are still posting...`,
        });
      }
      setPollingClaimIds(newPollingIds);
      updateStore({
        claims,
        type: "SET_CLAIMS",
      });
    }, POLL_INTERVAL);
    return () => {
      clearInterval(pollInterval);
    };
  }, [pollingClaimIds]);

  const loadClaims = async () => {
    await loadPractices();
    const claims = await queryClient.getClaimsProceduresAndPatient([]);
    updateStore({
      claims,
      type: "SET_CLAIMS",
    });
    if (claims.some(needsPolling)) {
      const pendingClaimIds = claims
        .filter(needsPolling)
        .map((claim) => claim.wieldyId);
      const ids = new Set(pendingClaimIds);
      setPollingClaimIds(ids);
    }
  };

  const postToPMS = async (claim: ClaimWithProcedureAndPatientMessage) => {
    try {
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...claim,
          postedState: PostedState[PostedState.POSTING],
        },
      });
      updateSnackBar({
        severity: "info",
        message: "Posting to PMS",
      });
      const postResponse = await queryClient.postToPMS(claim.wieldyId, () => {
        updateSnackBar({
          severity: "info",
          message: `Hang tight. Claim ${claim.claimId} is still posting...`,
        });
      });
      if (postingStatusInProgress(postResponse)) {
        updateSnackBar({
          severity: "info",
          message: `Hang tight. Claim ${claim.claimId} is posting...`,
        });
        updateStore({
          type: "UPDATE_CLAIM",
          claimToUpdate: {
            ...claim,
            postedState: postResponse.postedState,
            postedAttempts: postResponse.postedAttempts,
            postedLatestErrorMessage: postResponse.postedLatestErrorMessage,
          },
        });
        const newPollingClaimIds = new Set(pollingClaimIds);
        newPollingClaimIds.add(claim.wieldyId);
        setPollingClaimIds(newPollingClaimIds);
        return;
      }
      if (!postingStatusSuccess(postResponse)) {
        updateSnackBar({
          severity: "error",
          message:
            "There was an issue posting to PMS. Please reach out to Wieldy " +
            "support.",
        });
        updateStore({
          type: "UPDATE_CLAIM",
          claimToUpdate: {
            ...claim,
            postedState: postResponse.postedState,
            postedAttempts: postResponse.postedAttempts,
            postedLatestErrorMessage: postResponse.postedLatestErrorMessage,
          },
        });
        return;
      }
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...claim,
          postedState: postResponse.postedState,
          postedDateTime: postResponse.postedDateTime,
          postedAttempts: postResponse.postedAttempts,
        },
      });
      updateSnackBar({
        severity: "success",
        message: "Successfully posted to PMS",
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.data) {
        const responseBody = error.response.data;

        updateSnackBar({
          severity: "error",
          message: responseBody.postedLatestErrorMessage,
        });
        updateStore({
          type: "UPDATE_CLAIM",
          claimToUpdate: {
            ...claim,
            postedState: responseBody.postedState,
            postedAttempts: responseBody.postedAttempts,
            postedLatestErrorMessage: responseBody.postedLatestErrorMessage,
          },
        });
      }
    }
  };

  const resetClaims = () => {
    updateStore({
      type: "RESET_CLAIMS",
    });
  };

  const updateClaim = async (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => {
    updateStore({
      type: "UPDATE_CLAIM",
      claimToUpdate: { ...claim, ...claimWork },
    });
    await queryClient.updateClaim(claimWork);
  };

  const onSaveProcedure = async (procedure: ProcedureMessage) => {
    const wieldyIdParts = procedure.wieldyId.split(".");
    if (wieldyIdParts.length !== 4) {
      updateSnackBar({
        severity: "error",
        message: `Invalid Wieldy ID for procedure: ${procedure.wieldyId}`,
      });
      return;
    }
    const claimKey = wieldyIdParts.slice(0, 3).join(".");
    const existingClaim = store.claims.find((c) => c.wieldyId === claimKey);
    if (!existingClaim) {
      updateSnackBar({
        severity: "error",
        message: `Claim not found for procedure: ${claimKey}`,
      });
      return;
    }
    const existingProcedure = existingClaim.Procedure.find(
      (p) => p.wieldyId === procedure.wieldyId,
    );
    if (existingProcedure) {
      const deltas = (
        Object.entries(procedure) as [keyof ProcedureMessage, unknown][]
      ).reduce<Partial<ProcedureMessage>>((acc, [key, value]) => {
        // We only need practiceId to update the procedure
        if (key === "practiceId") {
          return acc;
        }
        if (value !== undefined && existingProcedure[key] !== value) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (acc[key] as any) = value;
        }
        return acc;
      }, {} as Partial<ProcedureMessage>);
      if (Object.keys(deltas).length === 0) {
        updateSnackBar({
          severity: "warning",
          message: `Nothing to update`,
        });
        return;
      }
      await queryClient.updateProcedure({
        ...deltas,
        wieldyId: procedure.wieldyId,
        practiceId: procedure.practiceId,
      });
      updateStore({
        type: "UPDATE_PROCEDURE",
        claimKey,
        procedure,
      });
      updateSnackBar({
        severity: "success",
        message: `Successfully edited procedure: ${procedure.wieldyId}`,
      });
      return;
    }
    updateStore({
      type: "CREATE_PROCEDURE",
      claimKey,
      procedure,
    });
    await queryClient.createProcedure(procedure);
    updateSnackBar({
      severity: "success",
      message: `Successfully create procedure: ${procedure.wieldyId}`,
    });
  };

  const onSaveClaim = async (claim: ClaimMessage) => {
    const existingClaim = store.claims.find(
      (c) => c.wieldyId === claim.wieldyId,
    );
    if (existingClaim) {
      const deltas = (
        Object.entries(claim) as [keyof ClaimMessage, unknown][]
      ).reduce<Partial<ClaimMessage>>((acc, [key, value]) => {
        const existingValue =
          existingClaim[key] && key === "payer"
            ? (existingClaim[key] as string).toUpperCase()
            : existingClaim[key];
        if (value !== undefined && existingValue !== value) {
          // Updates on the backend currently only accept string
          acc[key] = value;
        }
        return acc;
      }, {} as Partial<ClaimMessage>);
      if (Object.keys(deltas).length === 0) {
        updateSnackBar({
          severity: "warning",
          message: `Nothing to update`,
        });
        return;
      }
      const wieldyId = await queryClient.updateStringifyClaim({
        ...deltas,
        wieldyId: claim.wieldyId,
        practiceId: claim.practiceId,
      });
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...existingClaim,
          ...claim,
        },
      });
      updateSnackBar({
        severity: "success",
        message: `Successfully edited claim: ${wieldyId}`,
      });
      return;
    }

    const wieldyId = await queryClient.createClaim(claim);
    const practice = selectedPractices.find(
      (p) => p.wieldyId === claim.practiceId,
    );
    const createdClaim: ClaimWithProcedureAndPatientMessage = {
      ...(claim as ClaimMessage),
      availableSince: getTodayISODate(),
      practice: practice?.name || "",
      practiceDisplayName: practice?.displayName || "",
      Patient: [],
      Procedure: [],
    };
    updateStore({
      type: "CREATE_CLAIM",
      createdClaim,
    });
    updateSnackBar({
      severity: "success",
      message: `Successfully created claim: ${wieldyId}`,
    });
  };

  const batchPostPMS = async () => {
    try {
      updateIsBatchPosting(true);
      updateSnackBar({
        severity: "info",
        message: "Started batching posting to PMS",
      });
      await queryClient.batchPostToPMS();
      updateSnackBar({
        severity: "success",
        message: "Successfully started batch posting to PMS",
      });
      updateIsBatchPosting(false);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        updateSnackBar({
          severity: "error",
          message: "Failed to start batch posting to PMS",
        });
      }
      updateIsBatchPosting(false);
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ClaimsListView
            load={loadClaims}
            reset={resetClaims}
            claims={store.claims}
            loading={!store.loadedClaims}
            batchPostPMS={batchPostPMS}
            isBatchPosting={isBatchPosting}
            updateClaim={updateClaim}
            postToPMS={postToPMS}
            selectedPractices={selectedPractices}
            onSaveClaim={onSaveClaim}
            onSaveProcedure={onSaveProcedure}
            supportedPayers={supportedPayers}
          />
        }
      />
    </Routes>
  );
}
