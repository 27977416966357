import { Box, FormControl, Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import head from "lodash/head";
import React from "react";
import { Control, Controller } from "react-hook-form";

import { toSourceFileId } from "../../api/sources";
import { ReviewStatus } from "../../status";
import { InputWrapper } from "../CredentialsDashboard/ui/InputWrapper";
import { ErrorHelperText } from "../PatientsDashboard/ui/ErrorHelper";
import UploadFile from "../UploadFile";
import { AddClaimFormFields, editableClaimFields } from "./CrudForms.zod";
import { renderField } from "./FormFieldRenderer";

export const MANUAL_UPLOAD = "MANUAL_UPLOAD";
const ALLOWED_FILE_TYPES = ["application/pdf"];

interface ClaimFormProps {
  control: Control<AddClaimFormFields>;
  practices: PracticeMessage[];
  supportedPayers: CredentialsSupportedPayersMessage[];
  enablePDFUpload: boolean;
  payment: PaymentMessage | null;
  setClaimFileToUpload: (file: File | null) => void;
  claimFileToUpload: File | null;
  setFileError: (error: string | null) => void;
  fileError: string | null;
}

export default function ClaimForm({
  control,
  practices,
  supportedPayers,
  enablePDFUpload,
  payment,
  setClaimFileToUpload,
  claimFileToUpload,
  setFileError,
  fileError,
}: ClaimFormProps) {
  const theme = useTheme();

  const regularFields = editableClaimFields.filter(
    (field) => !["dropdown", "radio"].includes(field.type),
  );

  const fileId = payment ? head(toSourceFileId(payment?.documents)) : null;
  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: "600" }}>
        Claim Details
      </Typography>
      <Box sx={{ margin: `${theme.spacing(3)} 0` }}>
        <Grid container spacing={2}>
          {payment && enablePDFUpload && (
            <Grid item xs={12}>
              <Controller
                name="fileId"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <>
                    <FormControl sx={{ width: "100%" }}>
                      <InputWrapper>
                        <FormControl component="fieldset">
                          <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            Claim PDF Document *
                          </Typography>
                          <RadioGroup
                            name="fileId"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                          >
                            <FormControlLabel
                              value={fileId}
                              control={<Radio />}
                              label="Use Payment PDF"
                              disabled={!fileId}
                            />
                            <FormControlLabel
                              value={MANUAL_UPLOAD}
                              control={<Radio />}
                              label="Upload New PDF"
                            />
                          </RadioGroup>
                        </FormControl>
                        <ErrorHelperText
                          message={fileError || error?.message}
                        />
                      </InputWrapper>
                    </FormControl>
                    {value === MANUAL_UPLOAD && (
                      <UploadFile
                        file={claimFileToUpload}
                        setFile={setClaimFileToUpload}
                        onError={setFileError}
                        allowedFileTypes={ALLOWED_FILE_TYPES}
                      />
                    )}
                  </>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Controller
              name="reviewStatus"
              control={control}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { error },
              }) => (
                <FormControl sx={{ width: "100%" }}>
                  <InputWrapper>
                    <TextField
                      select
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ""}
                      name="reviewStatus"
                      inputRef={ref}
                      label="Review Status"
                      variant="outlined"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {Object.values(ReviewStatus).map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorHelperText message={error?.message} />
                  </InputWrapper>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="practiceId"
              control={control}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { error },
              }) => (
                <FormControl sx={{ width: "100%" }}>
                  <InputWrapper>
                    <TextField
                      select
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ""}
                      name="practiceId"
                      inputRef={ref}
                      label="Practice"
                      variant="outlined"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {practices.map((practice) => (
                        <MenuItem
                          key={practice.wieldyId}
                          value={practice.wieldyId}
                        >
                          {practice.displayName}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorHelperText message={error?.message} />
                  </InputWrapper>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="payer"
              control={control}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { error },
              }) => (
                <FormControl sx={{ width: "100%" }}>
                  <InputWrapper>
                    <TextField
                      select
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ""}
                      name="payer"
                      inputRef={ref}
                      label="Payer"
                      variant="outlined"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {supportedPayers.map((payer) => (
                        <MenuItem key={payer.wieldyId} value={payer.name}>
                          {payer.displayLabel}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorHelperText message={error?.message} />
                  </InputWrapper>
                </FormControl>
              )}
            />
          </Grid>
          {regularFields.map((field) =>
            renderField<AddClaimFormFields>(control, field),
          )}
        </Grid>
      </Box>
    </>
  );
}
